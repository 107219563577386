import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  creditCardSchema,
  CreditCardSchema,
} from '../components/HirePlanForm/data/schema/creditCardSchema';
import {
  debitSchema,
  DebitSchema,
} from '../components/HirePlanForm/data/schema/debitSchema';
import {
  billetSchema,
  BilletSchema,
} from '../components/HirePlanForm/data/schema/billetSchema';

import { useHirePlanFormStepStore } from '../stores/useHirePlanFormStepStore';
import { usePaymentStore } from '../stores/usePaymentStore';
import { useBoostedPlanDataStore } from '../stores/useBoostedPlanDataStore';
import { useLoadingStore } from '../stores/useLoadingStore';
import { useError } from '../stores/useError';

type PaymentFormSchema = CreditCardSchema & DebitSchema & BilletSchema;

interface HirePlanFormContextProps {
  register: UseFormRegister<PaymentFormSchema>;
  control: Control<PaymentFormSchema, any>;
  watch: UseFormWatch<PaymentFormSchema>;
  handleSubmit: UseFormHandleSubmit<PaymentFormSchema>;
  onSubmit: (schema: PaymentFormSchema) => void;
  goToNextForm: (schema: PaymentFormSchema) => void;
  errors: FieldErrors<PaymentFormSchema>;
  clearErrors: UseFormClearErrors<PaymentFormSchema>;
  unregister: UseFormUnregister<PaymentFormSchema>;
  setValue: UseFormSetValue<PaymentFormSchema>;
}

const HirePlanFormContext = createContext({} as HirePlanFormContextProps);

interface HirePlanFormProviderProps {
  children: ReactNode;
}

export function HirePlanFormProvider({
  children,
}: HirePlanFormProviderProps): ReactElement {
  const initialPlanData = useBoostedPlanDataStore(state => state.data);
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);
  const paymentId = usePaymentStore(state => state.id);
  const navigate = useNavigate();

  const setLoading = useLoadingStore(state => state.setLoading);
  const setError = useError(state => state.setError);
  const setTitle = useError(state => state.setErrorTitle);
  const setText = useError(state => state.setErrorText);

  interface CheckoutData {
    token: string;
    cpf?: string;
    nomeTitular?: string;
    dataNascimento?: string;
    emailTitular?: string;
    cep?: string;
    idUf?: string;
    endereco?: string;
    numeroCasa?: string;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    celular?: string;
    nomeMae?: string;
    cns?: string;
    rg?: string;
    idSexo?: string;
    idEstadoCivil?: string;
    idOrgaoExpedidor?: string;
    idOrgaoExpedidorUf?: string;
    formaPagamento?: object;
    vencimentoBoleto?: string;
    vencimentoDebito?: string;
    dependentes?: object;
    cartaoCredito?: any;
    idBanco?: string;
    conta?: string;
    agencia?: string;
    operacao?: string;
    primeiraBoleto?: boolean;
    responsavelFinanceiro: object;
  }

  function getSchemaResolver() {
    switch (paymentId) {
      //Credit Card
      case 1: {
        return creditCardSchema;
      }

      case 2: {
        //Debit
        return billetSchema;
      }

      case 3: {
        //Billet
        return debitSchema;
      }

      default: {
        return creditCardSchema;
      }
    }
  }

  function removeUnusedMethod(checkoutData: CheckoutData) {
    switch (paymentId) {
      //Credit Card
      case 1: {
        return checkoutData;
      }

      case 2: {
        //Debit
        delete checkoutData['cartaoCredito'];
        return checkoutData;
      }

      case 3: {
        //Billet
        delete checkoutData['cartaoCredito'];
        //delete checkoutData['vencimentoDebito'];
        return checkoutData;
      }
    }
  }

  function getPaymentData(data: PaymentFormSchema) {
    switch (paymentId) {
      case 1: {
        //Credit Card
        return {
          formaPagamento: {
            gpPagto: 1,
            idPagto: 1,
          },
        };
      }
      case 2: {
        //Debit
        return {
          formaPagamento: {
            gpPagto: 2,
            idPagto: 6, //TODO: pegar id dinamico
          },
        };
      }
      case 3: {
        //Billet
        return {
          formaPagamento: {
            gpPagto: 3,
            idPagto: 4,
          },
        };
      }
    }
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<DebitSchema>({
    defaultValues: {
      user: {
        beneficiaryIsSameAsFinancialResponsible: true,
      },
      payment: {
        firstPaymentBillet: false,
      },
    },
    resolver: zodResolver(getSchemaResolver()),
  });

  const goToNextForm: SubmitHandler<PaymentFormSchema> = () => {
    goToNextStep();
  };

  const onSubmit: SubmitHandler<PaymentFormSchema> = async data => {
    console.log('chegando a enviar...')
    const banco = watch('payment.bank');
    const conta = watch('payment.debitAcount');
    const agencia = watch('payment.agency');
    const operacao = watch('payment.operation');
    const primeiraBoleto = watch('payment.firstPaymentBillet');
    const vencimentoBoleto = watch('payment.billetDate');
    const vencimentoDebito = watch('payment.debitDate');
    const payment = getPaymentData(data);
    const financialResponsible =
      data.user.beneficiaryIsSameAsFinancialResponsible == true
        ? {
            responsavelFinanceiro: {
              cpf: data.user.documents?.cpf?.replace(
                /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
                '$1$2$3$4',
              ),
              nome: data.user.fullName,
              dataNascimento: data.user.birthDate,
              email: data.user.email,
              cep: data.user.address?.cep,
              endereco: data.user.address?.street,
              cidade: data.user.address?.city,
              numero: data.user.address?.number,
              complemento: data.user.address?.complement,
              bairro: data.user.address?.neighborhood,
              idUf: data.user.address?.uf,
              telefone: data.user.phone,
            },
          }
        : {
            responsavelFinanceiro: {
              cpf: data.user.financialResponsible?.cpf?.replace(
                /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
                '$1$2$3$4',
              ),
              nome: data.user.financialResponsible?.fullName,
              dataNascimento: data.user.financialResponsible?.birthDate,
              email: data.user.financialResponsible?.email,
              cep: data.user.financialResponsible?.address?.cep,
              endereco: data.user.financialResponsible?.address?.street,
              cidade: data.user.financialResponsible?.address?.city,
              numero: data.user.financialResponsible?.address?.number,
              complemento: data.user.financialResponsible?.address?.complement,
              bairro: data.user.financialResponsible?.address?.neighborhood,
              idUf: data.user.financialResponsible?.address?.uf,
              telefone: data.user.financialResponsible?.phone,
            },
          };

    const checkoutData: CheckoutData = {
      token: initialPlanData.token,
      cpf: data.user.documents?.cpf?.replace(
        /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
        '$1$2$3$4',
      ),
      nomeTitular: data.user.fullName,
      dataNascimento: data.user.birthDate,
      emailTitular: data.user.email,
      cep: data.user.address?.cep,
      idUf: data.user.address?.uf,
      endereco: data.user.address?.street,
      numeroCasa: data.user.address?.number,
      complemento: data.user.address?.complement,
      bairro: data.user.address?.neighborhood,
      cidade: data.user.address?.city,
      celular: data.user.phone,
      nomeMae: data.user.fullMothersName,
      cns: data.user.documents?.cns,
      rg: data.user.documents?.rg,
      idSexo: data.user.gender,
      idEstadoCivil: data.user.civilStatus,
      idOrgaoExpedidor: data.user.documents?.issuingAgency,
      idOrgaoExpedidorUf: data.user.documents?.ufIssuingAgency,
      formaPagamento: payment?.formaPagamento,
      vencimentoBoleto: vencimentoBoleto,
      vencimentoDebito: vencimentoDebito,
      dependentes: data.user.dependents?.map(dependent => ({
        nome: dependent.name,
        cpf: dependent.cpf.replace(
          /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
          '$1$2$3$4',
        ),
        rg: dependent.rg,
        idOrgaoExpedidor: dependent.issuingAgency,
        idOrgaoExpedidorUf: dependent.issuingAgencyUF,
        cns: dependent.cns == "" ? null : dependent.cns,
        dataNascimento: dependent.birthDate,
        nomeMae: dependent.mothersName,
        idSexo: dependent.gender,
        idParentesco: dependent.parentage,
      })),
      cartaoCredito: {
        codigoSeguranca: data.payment?.cvv,
        numero: data.payment?.number,
        nome: data.payment?.name,
        expiracao: data.payment?.expireDate,
      },
      idBanco: banco,
      conta: conta,
      agencia: agencia,
      operacao: operacao,
      primeiraBoleto: primeiraBoleto,
      responsavelFinanceiro: financialResponsible?.responsavelFinanceiro,
    };
    setLoading(true);

    const planValue = await fetch(
      `${process.env.REACT_APP_OG_PAYMENT_INDIVIDUAL}`,
      {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': 'https:dev.vendas.odontogroup.com.br',
        },
        method: 'POST',
        body: JSON.stringify(removeUnusedMethod(checkoutData)),
      },
    )
      .then(response => {
        if (response.ok) {
          setLoading(false);
          setError(false);
          return response.json();
        }
        return response.json().then(text => {
          setTitle(text.code);
          setText(text.error);
          throw new Error(text);
        });
      })
      .then(response => navigate('/proposta', { state: response }))
      .catch(error => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <HirePlanFormContext.Provider
      value={{
        register,
        control,
        handleSubmit,
        watch,
        onSubmit,
        goToNextForm,
        errors,
        clearErrors,
        unregister,
        setValue,
      }}
    >
      {children}
    </HirePlanFormContext.Provider>
  );
}

export function useHirePlanForm(): HirePlanFormContextProps {
  return useContext(HirePlanFormContext);
}
